import { createTheme } from '@mui/material/styles';
import { grey, teal } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    neutral: Palette['primary'];
    promo: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    promo: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    neutral: true;
    promo: true;
  }
}

const darkPalette = {
  primary: '#a6c8ff',
  onPrimary: '#003063',
  primaryContainer: '#a6c8ff',
  onPrimaryContainer: '#d4e3ff',
  secondary: '#bdc7dc',
  onSecondary: '#273141',
  secondaryContainer: '#bdc7dc',
  onSecondaryContainer: '#dae3f9',
  tertiary: '#dbbde1',
  onTertiary: '#3e2845',
  tertiaryContainer: '#dbbde1',
  onTertiaryContainer: '#f8d8fe',
  error: '#ffb4a9',
  onError: '#680003',
  errorContainer: '#ffb4a9',
  onErrorContainer: '#ffdad4',
  background: '#1b1b1d',
  onBackground: '#e3e2e6',
  surface: '#1b1b1d',
  onSurface: '#e3e2e6',
  outline: '#8e919a',
  surfaceVariant: '#44474f',
  onSurfaceVariant: '#c3c6cf',
  inverseSurface: '#e3e2e6',
  inverseOnSurface: '#1b1b1d',
};

const lightPalette = {
  primary: '#005db5',
  onPrimary: '#ffffff',
  primaryContainer: '#005db5',
  onPrimaryContainer: '#001b3d',
  secondary: '#555f71',
  onSecondary: '#ffffff',
  secondaryContainer: '#555f71',
  onSecondaryContainer: '#121c2b',
  tertiary: '#6f5676',
  onTertiary: '#ffffff',
  tertiaryContainer: '#6f5676',
  onTertiaryContainer: '#28132f',
  error: '#ba1b1b',
  onError: '#ffffff',
  errorContainer: '#ba1b1b',
  onErrorContainer: '#410001',
  background: '#fdfbff',
  onBackground: '#1b1b1d',
  surface: '#fdfbff',
  onSurface: '#1b1b1d',
  outline: '#74777f',
  surfaceVariant: '#e0e2eb',
  onSurfaceVariant: '#44474f',
  inverseSurface: '#2f3033',
  inverseOnSurface: '#f1f0f4',
};

const createAppTheme = (prefersDarkMode: boolean) => {
  const palette = prefersDarkMode ? darkPalette : lightPalette;
  return createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: palette.primary,
        contrastText: palette.onPrimary,
      },
      secondary: {
        main: palette.secondary,
        contrastText: palette.onSecondary,
      },
      tertiary: {
        main: palette.tertiary,
        contrastText: palette.onTertiary,
      },
      error: {
        main: palette.error,
        contrastText: palette.onError,
      },
      promo: {
        main: '#2B4DD4',
      },
      neutral: {
        main: prefersDarkMode ? grey[800] : grey[100],
      },
      background: {
        default: palette.background,
        paper: palette.surface,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: [
        'Fira Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      // Name of the component
      MuiDialog: {
        styleOverrides: {
          // Name of the slot
          paper: {
            // Some CSS
            borderRadius: '28px',
            boxShadow: 'none',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            // Some CSS
            borderRadius: '4px 4px 0 0',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '24px 24px 16px 24px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '0 24px 24px 24px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // borderRadius: '12px',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            borderRadius: '16px',
            padding: '28px',
            height: '96px',
            minWidth: '96px',
            textTransform: 'capitalize',
          },
          sizeMedium: {
            height: '56px',
            minWidth: '56px',
            padding: '16px',
          },
          sizeSmall: {
            borderRadius: '12px',
            padding: '12px',
            height: '40px',
            minWidth: '40px',
          },
          extended: {
            width: 'auto',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            height: '40px',
            padding: '0 24px',
            textTransform: 'none',
            fontSize: 14,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {},
      },
      MuiAppBar: {
        styleOverrides: {
          root: { backgroundColor: palette.surface },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: { height: 32, borderRadius: 8 },
        },
      },
    },
  });
};

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
      contrastText: '#fff',
    },
    promo: {
      main: '#2B4DD4',
    },
    secondary: {
      main: '#16E3FF',
    },
    neutral: {
      main: grey[100],
    },
    tertiary: {
      main: '#16E3FF',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      'Work Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;

export { darkPalette, lightPalette, createAppTheme };
