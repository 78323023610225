import { CacheProvider, EmotionCache } from '@emotion/react';
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';

import { AppProps } from 'next/app';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import { SWRConfig } from 'swr';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import createEmotionCache from '../src/createEmotionCache';
import fetcher from 'src/fetcher';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createAppTheme } from 'src/theme';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps): React.ReactElement<AppProps> => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // Create a theme instance.
  const theme = useMemo(() => createAppTheme(prefersDarkMode), [prefersDarkMode]);
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>DataHub</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SWRConfig
          value={{
            fetcher,
            refreshInterval: 0,
          }}
        >
          <UserProvider>
            <Component {...pageProps} />
          </UserProvider>
        </SWRConfig>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MyApp;
